import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiUrl } from './../helpers/Global';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

class UserIndex extends Component 
{

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user,
            notifs: null,
            notifs_count: 0,
            commands: null,
        };
    }

    componentDidMount() {
        axios.post(apiUrl(`users/dashboard`)).then(res => {
            const data = res.data;
            if ( data.error_code === 401 ) {
                if (typeof this.props.logout === 'function') {
                    this.props.logout();
                }
            } else {
                this.setState({
                    notifs: data.notifs,
                    notifs_count: data.notifs_count,
                    commands: data.commands,
                });
            }
        });
    }

    addLevel() {
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage('add-level');
    }

    render() {
        var auth_user = this.state.auth_user || {};
        if ( auth_user.state !== 1 ) {
            return (
                <div id='user-index'>
                    <p className='text-muted text-center'>
                        Merci pour votre inscription <br />
                        Votre compte est en cours de validation...
                    </p>
                </div>
            );
        } else {
            const commands = this.state.commands;
            return (
                <div id='user-index'>
                    { this.state.notifs && !!this.state.notifs_count && 
                        <div className='table-responsive'>
                            <h3>Notifications</h3>
                            {
                                this.state.notifs
                                .sort(function(a, b) {
                                    a = new Date(a.created_at);
                                    b = new Date(b.created_at);
                                    return a > b ? -1 : a < b ? 1 : 0;
                                }).map((notif) =>
                                    <div key={notif.id} className="notif">
                                        <small>{ moment(notif.created_at).fromNow() }</small>
                                        <p>{ notif.description }</p>
                                        <div>
                                            { notif.command_id ? (<Link to={`/commands/${notif.command_id}`} onClick={this.addLevel} className='link'>Voir</Link>) : '' }
                                            { notif.message_id ? (<Link to={`/supports/${notif.message_id}`} onClick={this.addLevel} className='link'>Voir</Link>) : '' }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    }

                    { commands && !!commands.length && Object.entries(commands).map(([type, cmds], index) =>
                        <div key={index}>
                            <hr/>
                            <h3>{ type }</h3>
                            <div className='table-responsive'>
                                <table className='table small list'>
                                    <tbody>
                                        { cmds
                                        .sort(function(a, b) {
                                            a = new Date(a.created_at);
                                            b = new Date(b.created_at);
                                            return a > b ? -1 : a < b ? 1 : 0;
                                        }).map((cmd, index) => 
                                        <tr key={index}>
                                            <td>{ cmd.num }</td>
                                            <td><small>{ moment(cmd.created_at).fromNow() }</small></td>
                                            <td><Link to={`/commands/${cmd.id}`} onClick={this.addLevel} className='link'>Voir</Link></td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}

                    { (commands && !commands.length) && (this.state.notifs && !this.state.notifs_count) &&
                        <p className='text-muted text-center'>Aucune commande | notification trouvée!</p>
                    }

                    <div id="spinner" className={commands || this.state.notifs ? 'hidden' : ''}></div>
                </div>
            );
        }
        
    }

}

export default withRouter(UserIndex);
