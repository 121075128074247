import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

class Footer extends Component
{

    constructor(props) {
        super(props);
        this.state = {
            currentPage: this.props.history.location.pathname.slice(1),
            auth_user: props.auth_user,
            notifs_count: props.notifs_count
        }
    }

    componentWillReceiveProps() {
        this.setState({ currentPage: this.props.history.location.pathname.slice(1) })
    }

    render() {
        var auth_user = this.state.auth_user || {};

        //var notifs_count = this.state.notifs_count || 0; 
        if ( auth_user.user_type === 'livreur' ) {
            return (
                <div id="footer">
                    <div className={"tab" + (this.state.currentPage.indexOf('notifications') != -1 ? ' active' : '')}>
                        <Link to="/notifications" replace className='txt'>
                            <img src={require('../assets/images/icons/bell.svg')} className='img' alt='Notifications' />
                            Notifications
                        </Link>
                    </div>
                    <div className={"tab" + (this.state.currentPage === 'indelivery' ? ' active' : '')}>
                        <Link to="/indelivery" replace className='txt'>
                            <img src={require('../assets/images/icons/truck.svg')} className='img' alt='En livraison' />
                            En livraison
                        </Link>
                    </div>
                    <div className={"tab" + (this.state.currentPage.indexOf('commands') != -1 ? ' active' : '')}>
                        <Link to="/commands" replace className='txt'>
                            <img src={require('../assets/images/icons/shopping-bag.svg')} className='img' alt='Mes commandes' />
                            Livraisons
                        </Link>
                    </div>
                    <div className={"tab" + (this.state.currentPage.indexOf('support') != -1 ? ' active' : '')}>
                        <Link to="/support" replace className='txt'>
                            <img src={require('../assets/images/icons/mail.svg')} className='img' alt='Contact' />
                            Support
                        </Link>
                    </div>
                </div>
            );
        } else if ( auth_user.user_type === 'pro' ) {
            return (
                <div id="footer">
                    <div className={"tab" + (this.state.currentPage === 'command/create' ? ' active' : '')}>
                        <Link to="/command/create" replace className='txt'>
                            <img src={require('../assets/images/icons/plus-square.svg')} className='img' alt='Nouvelle commande' />
                            Nouvelle
                        </Link>
                    </div>
                    <div className={"tab" + (this.state.currentPage.indexOf('notifications') != -1 ? ' active' : '')}>
                        <Link to="/notifications" replace className='txt'>
                            <img src={require('../assets/images/icons/bell.svg')} className='img' alt='Notifications' />
                            Notifications
                        </Link>
                    </div>
                    <div className={"tab" + (this.state.currentPage.indexOf('commands') != -1 ? ' active' : '')}>
                        <Link to="/commands" replace className='txt'>
                            <img src={require('../assets/images/icons/shopping-bag.svg')} className='img' alt='Mes commandes' />
                            Commandes
                        </Link>
                    </div>
                    <div className={"tab" + (this.state.currentPage.indexOf('support') != -1 ? ' active' : '')}>
                        <Link to="/support" replace className='txt'>
                            <img src={require('../assets/images/icons/mail.svg')} className='img' alt='Contact' />
                            Support
                        </Link>
                    </div>
                </div>
            );
        } else {
            return (
                ''
                /*<div id="footer">
                    <div className={"tab" + (this.state.currentPage === '' ? ' active' : '')}>
                        <Link to="/" replace className='txt'>
                            <img src={require('../assets/images/icons/home-white.svg')} className='img' alt='Accueil' />
                            Accueil
                        </Link>
                    </div>
                    <div className={"tab" + (this.state.currentPage === 'register' ? ' active' : '')}>
                        <Link to="/register" replace className='txt'>
                            <img src={require('../assets/images/icons/user-plus.svg')} className='img' alt='inscrire' />
                            S'inscrire
                        </Link>
                    </div>
                    <div className={"tab" + (this.state.currentPage === 'login' ? ' active' : '')}>
                        <Link to="/login" replace className='txt'>
                            <img src={require('../assets/images/icons/alert-circle.svg')} className='img' alt='Se connecter' />
                            Se connecter
                        </Link>
                    </div>
                    <div className={"tab" + (this.state.currentPage === 'contact' ? ' active' : '')}>
                        <Link to="/contact" replace className='txt'>
                            <img src={require('../assets/images/icons/mail.svg')} className='img' alt='Contact' />
                            Contact 
                        </Link>
                    </div>
                </div>*/
            );
        }

    }

}

export default withRouter(Footer);
