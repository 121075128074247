import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import axios from 'axios';
import { apiUrl, authUser } from './helpers/Global';

class Register extends Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            error: null,
            inputs: {},
            cities: [],
            cities_areas: [],
            quartiers: []
        };
    }

    componentDidMount() {
        axios.post(apiUrl(`cities`)).then(res => {
            const data = res.data;
            this.setState({
                cities: data.cities,
                cities_areas: data.cities_areas
            });
        });
    }

    render() {
        return (
            <div id='app-root'>
                <div className='content' id='register'>
                    <div className='container'>

                        <div className='page-title'><h2>S'inscrire</h2></div>

                        <div className='text-center btns-grid'>
                            <Link to='/inscription-livreur' replace className='btn btn-grid'>
                                <img src={require('../assets/images/icons/scooter.svg')} className='icon' alt='livreur' />
                                Compte livreur
                            </Link>
                            <Link to='/register' replace className='btn btn-grid active'>
                                <img src={require('../assets/images/icons/order.svg')} className='icon' alt='commerçant' />
                                Compte commerçant
                            </Link>
                            
                        </div>

                        <form className='form' onSubmit={this.handleSubmit}>
                            <div className='form-group'>
                                <label>Nom &amp; prénom <small className='red'>(*)</small></label>
                                <input type='text' name='name' className='form-control' onChange={this.inputHandling}  required />
                            </div>
                            <div className='form-group'>
                                <label>Société <small className='red'>(*)</small></label>
                                <input type="text" name='society' className='form-control' onChange={this.inputHandling} required />
                            </div>
                            <div className='form-group'>
                                <label>E-mail <small className='red'>(*)</small></label>
                                <input type='email' name='email' className='form-control' onChange={this.inputHandling} required />
                            </div>
                            <div className='form-group'>
                                <label>Téléphone <small className='red'>(*)</small></label>
                                <input type='text' name='phone' className='form-control' onChange={this.inputHandling} required />
                            </div>
                            <div className='form-group clearfix'>
                                <div className='col-sm-6 pr-3'>
                                    <label>Ville <small className='red'>(*)</small></label>
                                    { !this.state.city_text ? (
                                        <select className='form-control' name='city_id' onChange={this.inputCityHandling} required>
                                            <option value=''>-- Choisir la ville --</option>
                                            { this.state.cities.map((city) =>
                                                <option value={city.id} key={city.id}>{ city.name }</option>
                                            )}
                                            <option value='-1'>-- Autres --</option>
                                        </select>
                                    ) : (
                                        <input type='text' name='city' className='form-control' onChange={this.inputHandling} placeholder='Saisir la ville' required autoFocus />
                                    ) }
                                </div>
                                <div className='col-sm-6'>
                                    <label>Quartier <small className='red'>(*)</small></label>
                                    { !this.state.city_area_text ? (
                                        <select className='form-control' name='city_area_id' onChange={this.inputAreaHandling} required>
                                            <option value=''>-- Choisir la quartier --</option>
                                            { this.state.quartiers.map((quartier) =>
                                                <option value={quartier.id} key={quartier.id}>{ quartier.name }</option>
                                            )}
                                            <option value='-1'>-- Autres --</option>
                                        </select>
                                    ) : (
                                        <input type='text' name='city_area' className='form-control' onChange={this.inputHandling} placeholder='Saisir la quartier' required autoFocus />
                                    ) }
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Adresse <small className='red'>(*)</small></label>
                                <input type="text" name='address' className='form-control' onChange={this.inputHandling} required />
                            </div>
                            <div className='form-group'>
                                <label>Catégorie <small className='red'>(*)</small></label>
                                { !this.state.usercategory_text ? (
                                    <select className='form-control' name='user_category' onChange={this.inputCategoryHandling} required>
                                        <option value=''>-- Choisir la catégorie --</option>
                                        <option value='Restaurant'>Restaurant</option>
                                        <option value='Boucherie'>Boucherie</option>
                                        <option value='-1'>-- Autres --</option>
                                    </select>
                                ) : (
                                    <input type='text' name='user_category' className='form-control' onChange={this.inputHandling} placeholder='Saisir la catégorie' required autoFocus />
                                ) }
                            </div>

                            <div className='form-group'>
                                <label>Mot de passe <small className='red'>(*)</small></label>
                                <input type='password' name='password' className='form-control' onChange={this.inputHandling} required />
                            </div>

                            <div className='form-group'>
								<div className='checkbox'>
									<input type='checkbox' id='conditions' name='conditions' onChange={this.inputHandling} />
									<label htmlFor='conditions' className='icon'></label>
									<label htmlFor='conditions' className='txt'>J'ai lu et j'accepte les <Link to='conditions' target='_blank'>conditions générales d'utilisation</Link>.</label>
								</div>
							</div>

                            { this.state.error ? (<div className='alert alert-danger'>{ this.state.error }</div>) : ('') }

                            <div className='mt-4'>
                                <button type='submit' className='btn w-100'>S'inscrire</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

    handleSubmit(event) {
        event.preventDefault();
        var inputs = this.state.inputs;
        inputs['is_pro'] = 1;
        inputs['notif_token'] = window.pushToken;
        axios.post(apiUrl(`users/create`), inputs).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ 
                    error: data.message,
                });
            } else if ( data.user ) {
                authUser().persist(data.user);
                if (typeof this.props.authenticate === "function") {
                    this.props.authenticate();
                }
                
                if (window.isNativeApp) {
                    window.ReactNativeWebView.postMessage('user-registred##' + JSON.stringify(data.user));
                }
                else {
                    this.props.history.push(`/`);
                }
            }
        });
    }

    inputCityHandling = (event) => {
        var target = event.target;
        var city_id = parseInt(target.value);
        if ( city_id === -1 ) {
            this.setState({ 
                city_text: 1,
                quartiers: []
            });
        } else {
            var cities_areas = this.state.cities_areas || [];
            var arr = [];
            cities_areas.forEach((area) => {
                if ( area.city_id === city_id ) {
                    arr.push(area);
                }
            });
            this.setState({ quartiers: arr }, function(){
                var inputs = this.state.inputs;
                inputs[target.name] = target.value;
                this.setState({ inputs: inputs });
            });
        }
    }

    inputAreaHandling = (event) => {
        var target = event.target;
        var area_id = parseInt(target.value);
        if ( area_id === -1 ) {
            this.setState({ city_area_text: 1 });
        } else {
            var inputs = this.state.inputs;
            inputs[target.name] = target.value;
            this.setState({ inputs: inputs });
        }
    }

    inputCategoryHandling = (event) => {
        var target = event.target;
        var area_id = parseInt(target.value);
        if ( area_id === -1 ) {
            this.setState({ usercategory_text: 1 });
        } else {
            var inputs = this.state.inputs;
            inputs[target.name] = target.value;
            this.setState({ inputs: inputs });
        }
    }

}

export default withRouter(Register);
