import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiUrl } from './../helpers/Global';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

class CommandsIndelivery extends Component
{

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user,
            commands: null,
        };
    }

    componentDidMount() {
        axios.get(apiUrl(`commands`)+'&state=indelivery').then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ error: data.message });
            } else {
                this.setState({ commands: data });
            }
        });
    }

    addLevel() {
        window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage('add-level');
    }

    render() {
        const commands = this.state.commands;
        return (
            <div id='commands'>
                <div className='page-title'><h2>En livraison</h2></div>
                { commands && !!commands.length && (
                    <div className='table-responsive'>
                        <table className='table small list'>
                            <tbody>
                                { commands
                                .sort(function(a, b) {
                                    a = new Date(a.created_at);
                                    b = new Date(b.created_at);
                                    return a > b ? -1 : a < b ? 1 : 0;
                                }).map((cmd) =>
                                    <tr key={cmd.id}>
                                        <td>{cmd.num}</td>
                                        <td><small>{ moment(cmd.created_at).fromNow() }</small></td>
                                        <td>
                                            <Link to={`/commands/${cmd.id}`} onClick={this.addLevel} className='link'>Voir</Link>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}

                {
                    commands && !commands.length &&
                    <p className='text-muted text-center'>Aucune livraison !</p>
                }
                
                <div id="spinner" className={commands ? 'hidden' : ''}></div>
            </div>
        );
    }

}

export default withRouter(CommandsIndelivery);
