import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { apiUrl, authUser } from './helpers/Global';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: this.props.history.location.pathname.slice(1),
            show_menu: '',
            auth_user: props.auth_user,
            user_status: props.auth_user.user_status ? true : false
        }
    }

    componentWillReceiveProps() {
        this.setState({ currentPage: this.props.history.location.pathname.slice(1), auth_user: this.props.auth_user })
    }

    render() {
        const auth_user = this.state.auth_user;

        return (
            <React.Fragment>
                { auth_user.user_type === 'livreur' &&
                    <div className={`checkbox switch disp-switcher${this.state.show_menu}`}>
                        <input type='checkbox' id='check_user_status' name='check_user_status' onChange={this.changeUserStatus} checked={auth_user.user_status ? true : false} />
                        <label htmlFor='check_user_status' className='icon'></label>
                        <label htmlFor='check_user_status' className='txt'>{ auth_user.user_status ? 'Disponible' : 'Non disponible' }</label>
                    </div>
                }
                <div id='header'>
                    {/*<Link to='/' replace><img className="logo" src={require('../assets/images/logo.png')} alt='Logo' style={{ height: '50px' }} /></Link>*/}
                    
                    {
                        auth_user.user_type &&
                        <div className={`burger-menu${this.state.show_menu}`} onClick={()=>this.showMenu()}><img src={require('../assets/images/icons/menu.svg')} /></div>
                    }
                    
                    <div className={`menu${this.state.show_menu}`}>
                        { auth_user.id ? (
                            <ul className='nav'>
                                <li><Link to='/commands' className={this.state.currentPage === 'commands' ? 'colored' : ''} onClick={()=>this.closeMenu()}>{ auth_user.user_type==='livreur' ? 'Mes livraisons' : 'Mes commandes' }</Link></li>
                                { auth_user.user_type === 'pro' ? (
                                    <li><Link to='/command/create' className={this.state.currentPage === 'command/create' ? 'colored' : ''} onClick={()=>this.closeMenu()}>Nouvelle commande</Link></li>
                                ) : '' }
                                <li><Link to='/profil' className={this.state.currentPage === 'profil' ? 'colored' : ''} onClick={()=>this.closeMenu()}>Mon profil</Link></li>
                                <li><Link to='/' onClick={this.logout}>Se déconnecter</Link></li>
                                { auth_user.id === 1 ? (
                                    <li><Link to='/users' className={this.state.currentPage === 'users' ? 'colored' : ''} onClick={()=>this.closeMenu()}>Gérer les utilisateurs</Link></li>
                                ) : '' }
                            </ul>
                        ) : (
                            <ul className='nav'>
                                <li><Link to='/login' className={this.state.currentPage === 'login' ? 'colored' : ''} onClick={()=>this.closeMenu()}>Se connecter</Link></li>
                                <li><Link to='/register' className={this.state.currentPage === 'register' ? 'colored' : ''} onClick={()=>this.closeMenu()}>S'inscrire</Link></li>
                            </ul>
                        ) }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    changeUserStatus = (event) => {
        var auth_user = this.state.auth_user || {};
        var target = event.target;
        var inputs = {};
        this.setState({ user_status: target.checked }, function(){
            axios.post(apiUrl(`users/status`), inputs).then(res => {
                const data = res.data;
                if ( data.user ) {
                    auth_user.user_status = data.user.user_status;
                    authUser().persist(data.user);
                    if (typeof this.props.authenticate === "function") {
                        this.props.authenticate();
                    }
                }
            });
        });

        if (target.checked) {
            window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage('user-status-true');
        }
        else {
            window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage('user-status-false');
        }
    }

    showMenu = () => {
        let show_menu = this.state.show_menu;
        if ( show_menu === '' ) show_menu = ' active';
        else show_menu = '';
        this.setState({ 
            show_menu: show_menu,
            auth_user: this.props.auth_user
         });
    }

    closeMenu = () => {
        this.setState({ show_menu: '' });
    }

    logout = (e) => {
        if (typeof this.props.logout === 'function') {
            this.props.logout();
        }

        if (window.isNativeApp) {
            window.ReactNativeWebView.postMessage('user-logout');
            return false;
        }

        this.closeMenu();
    }

}

export default withRouter(Header);
