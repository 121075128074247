import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from './../helpers/Global';

class Users extends Component 
{

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user,
            users: []
        };
    }

    componentDidMount() {
        axios.get(apiUrl(`users`)).then(res => {
            this.setState({ users: res.data });
		});
    }

    render() {
        var users = this.state.users || [];
        return (
            <div id='users'>
                <div className='page-title'><h2>Liste des utilisateurs</h2></div>
                { users && users.length ? (
                    <div className='table-responsive'>
                        <table className='table small list'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom</th>
                                    <th>Type</th>
                                    <th>E-mail</th>
                                    <th>Tél</th>
                                    <th>Adresse</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { users.map((user) =>
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{user.name}</td>
                                        <td>{user.user_type}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                        <td>
                                            <div>{user.addresse}</div>
                                            <div>{user.city}</div>
                                            <div>{user.city_area}</div>
                                        </td>
                                        <td>
                                            {user.society && (<div>- Société: {user.society}</div>)}
                                            {user.ice && (<div>- ICE: {user.ice}</div>)}
                                            {user.cin && (<div>- CIN: {user.cin}</div>)}
                                            {user.user_category && (<div>- Catégorie: {user.user_category}</div>)}
                                        </td>
                                        <td>
                                            { user.state === 1 ? (
                                                <Link to='#' className='link' onClick={()=>this.ActivateUser(user.id, 0)}>Désactivé</Link>
                                            ) : (
                                                <Link to='#' className='link' onClick={()=>this.ActivateUser(user.id, 1)} style={{ backgroundColor: '#f9bb00' }}>Activé</Link>
                                            ) }
                                        </td>
                                    </tr>
                                )}
                                
                            </tbody>
                        </table>
                    </div>
                ) : (<p className='text-muted text-center'>Aucun utilisateur !</p>) }
            </div>
        );
    }

    ActivateUser = (user_id, state) => {
        var inputs = {};
        inputs['user_id'] = user_id;
        inputs['state'] = state;
        axios.post(apiUrl(`users/status`), inputs).then(res => {
            this.componentDidMount();
        });
    }

}

export default withRouter(Users);
