import React, { Component } from 'react';
import axios from 'axios';
import { withRouter, Link } from "react-router-dom";
import { apiUrl, dateFormat } from './../helpers/Global';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

class Notifications extends Component 
{

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user,
            notifs: props.notifs.length ? props.notifs : null,
            notifs_alert: {},
            notifs_action: {}
        };
    }

    componentDidMount() {
        axios.post(apiUrl(`users/dashboard`)+'&return=notifs').then(res => {
            const data = res.data;
			this.setState({ notifs: data.notifs });
		});
    }

    addLevel() {
        window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage('add-level');
    }

    render() {
        var auth_user = this.props.auth_user;

		return (
			<div id='notifications'>
				<div className='page-title'><h2>Notifications</h2></div>

                { auth_user.disponible_error && (
                    <div className='alert alert-danger'>{auth_user.disponible_error}. <Link to='/profil'>Mon profil</Link></div>
                )}

                { 
                    this.state.notifs &&
                    this.state.notifs
                    .sort(function(a, b) {
                        a = new Date(a.created_at);
                        b = new Date(b.created_at);
                        return a > b ? -1 : a < b ? 1 : 0;
                    }).map((notif) =>
                        <div key={notif.id} className="notif">
                            <small>{ moment(notif.created_at).fromNow() }</small>
                            <p>{ notif.description }</p>
                            {
                                notif.command_id &&
                                <React.Fragment>
                                    <table className="direction">
                                        <tbody>
                                            <tr>
                                                <td>{/*{notif.command.loading_city} - */}{notif.command.loading_city_area}</td>
                                                <td className='center'><img className='icon' src={require('../../assets/images/icons/arrow-right.svg')} alt='arrow' /></td>
                                                <td>{/*{notif.command.delivery_city} - */}{notif.command.delivery_city_area}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className='info'>
                                        <img className='icon' src={require('../../assets/images/icons/home.svg')} alt='icon' />
                                        <span><b>Catégorie :</b> {notif.command.loading_user_category}</span>
                                    </p>
                                    <p className='info'>
                                        <img className='icon' src={require('../../assets/images/icons/clock.svg')} alt='icon' />
                                        <span><b>Délai :</b> {notif.command.delivery_time}</span>
                                    </p>
                                    <p className='info'>
                                        <img className='icon' src={require('../../assets/images/icons/shopping-bag-accent.svg')} alt='icon' />
                                        <span><b>Frais de livraison :</b> {notif.command.delivery_fees} Dh</span>
                                    </p>
                                    
                                    { this.state.notifs_alert.id === notif.id && (<div className='alert alert-primary'>{this.state.notifs_alert.message}</div>) }
                                    
                                </React.Fragment>
                            }

                            { (notif.command_id) && (
                                <div className='clearfix'>
                                    { (notif.command.livreur_id || auth_user.user_type==='pro') ? (
                                        <Link to={`/commands/${notif.command_id}`} onClick={this.addLevel} className='link' style={{ float: 'right' }}>Voir</Link>
                                    ) : (
                                        <React.Fragment>
                                            <Link to='#' className='link' onClick={()=>this.saveAction(notif, notif.command_id, 'accept')}>Accepter</Link>
                                            <Link to='#' className='link' onClick={()=>this.saveAction(notif, notif.command_id, 'refuse')} style={{ backgroundColor: '#f9bb00' }}>Refuser</Link>
                                        </React.Fragment>
                                    ) }
                                </div>
                            ) }

                            { notif.message_id ? (<Link to={`/supports/${notif.message_id}`} className='link'>Voir</Link>) : '' }

                            { (!notif.message_id && !notif.command_id) && (<Link to='#' className='link' onClick={()=>this.readNotif(notif.id)}>Ok</Link>) }

                        </div>
                    )
                }
                
                {
                    this.state.notifs && !this.state.notifs.length &&
                    <p className='text-muted text-center'>Aucune notification !</p>
                }

                <div id="spinner" className={this.state.notifs ? 'hidden' : ''}></div>
			</div>
		);
    }

    readNotif = (id) => {
        var inputs = {};
        inputs['notif_id'] = id;
        axios.post(apiUrl(`notifications/read`), inputs).then(res => {
            this.componentDidMount();
        });
    }

    saveAction = (notif, command_id, action) => {
        var notifs_alert = this.state.notifs_alert;
        var auth_user = this.state.auth_user;
        var inputs = {};
        inputs['notif_id'] = notif.id;
        inputs['command_id'] = command_id;
        inputs['command_accept'] = action;
        axios.post(apiUrl(`commands/accept`), inputs).then(res => {
            const data = res.data;
            if ( data.error ) {
                notifs_alert.id = notif.id;
                notifs_alert.message = data.message;
                this.setState({ notifs_alert: notifs_alert });                
            } else if ( data.error===0 && data.message ) {
                notifs_alert.id = notif.id;
                notifs_alert.message = data.message;
                this.setState({ notifs_alert: notifs_alert }, function(){
                    notif.command.livreur_id = auth_user.id;
                });
            }
            this.componentDidMount();
        });
    }

}

export default withRouter(Notifications);
