import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { apiUrl, authUser } from './helpers/Global';

class UserConnect extends Component
{

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user,
            token: props.match.params.token
        };
    }

    componentDidMount() {
        axios.post(apiUrl(`users/connect/` + this.state.token)).then(res => {
            const data = res.data;
            if ( data.error === 0 && data.user ) {
                authUser().persist(data.user);
                if (typeof this.props.authenticate === "function") {
                    this.props.authenticate();
                }
                this.props.history.push('/');
            }
            else {
                window.ReactNativeWebView.postMessage('goto-login');
            }
        });
    }

    

    render() {
        return ('');
    }

}

export default withRouter(UserConnect);
