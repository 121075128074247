import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { apiUrl, authUser } from './../helpers/Global';

class Profil extends Component 
{

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            auth_user: props.auth_user,
            user: {},
            user_status: true,
            working_days: [],
            livreur_vehicle: props.auth_user.vehicle_type,
            days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
            inputs: {},
            cities: [],
            cities_areas: [],
            horaires: {}
        };
    }

    componentDidMount() {
        axios.get(apiUrl(`users/show`)).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ error: data.message });
            } else {
                this.setState({ 
                    user: data.user,
                    inputs: data.user,
                    user_status: data.user.user_status===1,
                    working_days: data.working_days,
                    cities: data.cities,
                    cities_areas: data.cities_areas
                }, function(){
                    var working_days = data.working_days || [];
                    var horaires = this.state.horaires;
                    //this.state.days.forEach(d => { horaires['working_' + d] = false; });
                    working_days.forEach(d => {
                        horaires['working_' + d.day] = d.working===1?true:false;

                        var arr_morning_from = d.morning_from.split(':');
                        horaires[d.day + '_morning_from_hours'] = arr_morning_from[0];
                        horaires[d.day + '_morning_from_minutes'] = arr_morning_from[1];

                        var arr_morning_to = d.morning_to.split(':');
                        horaires[d.day + '_morning_to_hours'] = arr_morning_to[0];
                        horaires[d.day + '_morning_to_minutes'] = arr_morning_to[1];

                        var arr_evening_from = d.evening_from.split(':');
                        horaires[d.day + '_evening_from_hours'] = arr_evening_from[0];
                        horaires[d.day + '_evening_from_minutes'] = arr_evening_from[1];

                        var arr_evening_to = d.evening_to.split(':');
                        horaires[d.day + '_evening_to_hours'] = arr_evening_to[0];
                        horaires[d.day + '_evening_to_minutes'] = arr_evening_to[1];
                    });
                    this.setState({ horaires: horaires });
                });
            }
        });
    }

    render() {
        var auth_user = this.state.auth_user;
        var user = this.state.user || {};
        var is_pro = auth_user.user_type === 'pro';
        var is_livreur = auth_user.user_type === 'livreur';
        var pro = this.state.auth_user;

        return (
            <div id='profil'>
                <div className='page-title'><h2>Mon profil</h2></div>
                <form className='form' method='post' onSubmit={this.handleSubmit}>

                    <div className='form-group'>
                        <label>Nom &amp; Prénom <small className='red'>(*)</small></label>
                        <input type='text' name='name' className='form-control' defaultValue={user.name} onChange={this.inputHandling} required />
                    </div>
                    { is_pro && (
                        <div className='form-group'>
                            <label>Société <small className='red'>(*)</small></label>
                            <input type="text" name='society' className='form-control' defaultValue={user.society} onChange={this.inputHandling} />
                        </div>
                    ) }
                    <div className='form-group'>
                        <label>E-mail <small className='red'>(*)</small></label>
                        <input type='email' name='email' className='form-control' defaultValue={user.email} onChange={this.inputHandling} required />
                    </div>
                    <div className='form-group'>
                        <label>Téléphone <small className='red'>(*)</small></label>
                        <input type="text" name='phone' className='form-control' defaultValue={user.phone} onChange={this.inputHandling} required />
                    </div>
                    <div className='form-group clearfix'>
                        <div className='col-sm-6 pr-3'>
                            <label>Ville <small className='red'>(*)</small></label>
                            <select className='form-control' name='city_id' value={user.city_id} onChange={this.inputHandling} required>
                                { this.state.cities.map((city) =>
                                    <option value={city.id} key={city.id}>{ city.name }</option>
                                )}
                            </select>
                        </div>
                        <div className='col-sm-6'>
                            <label>Quartier <small className='red'>(*)</small></label>
                            <select className='form-control' name='city_area_id' value={user.city_area_id?user.city_area_id:0} onChange={this.inputHandling}>
                                <option value=''>-- Toutes les quartiers --</option>
                                { this.state.cities_areas.map((area) =>
                                    <option value={area.id} key={area.id}>{ area.name }</option>
                                )}
                            </select>
                        </div>
                    </div>

                    { is_livreur && (
                        <React.Fragment>
                            <div className='form-group'>
                                <label>ICE <small className='red'>(*)</small></label>
                                <input type='text' name='ice' className='form-control' defaultValue={user.ice} onChange={this.inputHandling} />
                            </div>
                            <div className='form-group'>
                                <label>Numéro CIN <small className='red'>(*)</small></label>
                                <input type='text' name='cin' className='form-control' defaultValue={user.cin} onChange={this.inputHandling} />
                            </div>
                            <div className='form-group'>
                                <label>Recto carte auto-entrepreneur {user.recto_carte_entrepreneur && "(Déjà téléchargé)"}</label>
                                <input type='file' accept="image/*,application/pdf" name='recto_carte_entrepreneur' className='form-control dashed' onChange={this.inputFileHandling} />
                            </div>
                            <div className='form-group'>
                                <label>Verso carte auto-entrepreneur {user.recto_carte_entrepreneur && "(Déjà téléchargé)"}</label>
                                <input type='file' accept="image/*,application/pdf" name='verso_carte_entrepreneur' className='form-control dashed' onChange={this.inputFileHandling} />
                            </div>
                            <hr/>
                            <div className='form-group'>
                                <h3 className="mt-0">Livraison</h3>
                                <div className='text-center btns-grid mb-10'>
                                    { ['En moto', 'En voiture'].map(typ =>
                                        <button type='button' className={`btn btn-grid${ this.state.livreur_vehicle===typ?' active':''}`} key={Math.random()} onClick={() => this.changeLivreurVehicle(typ)}>{typ}</button>
                                    )}
                                </div>
                                <div className='text-center btns-grid'>
                                    { ['A pied', 'En vélo', 'En camion'].map(typ =>
                                        <button type='button' className={`btn btn-grid${ this.state.livreur_vehicle===typ?' active':''}`} key={Math.random()} onClick={() => this.changeLivreurVehicle(typ)}>{typ}</button>
                                    )}
                                </div>
                            </div>
                            <hr/>
                            <div className='form-group'>
                                <div className='checkbox switch'>
                                    <input type='checkbox' id='user_status' name='user_status' checked={this.state.user_status} onChange={this.userStatusHandling} />
                                    <label htmlFor='user_status' className='icon'></label>
                                    <label htmlFor='user_status' className='txt'>{ this.state.user_status ? 'Disponible' : 'Non disponible' }</label>
                                </div>
                            </div>
                            <div className='clearfix'>
                                <h3>Horaires</h3>
                                <table className='time-table table mb-4'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Matin</th>
                                            <th>Soir</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.state.days.map(day => 
                                            <tr key={day}>
                                                <th className='p-0'>
                                                    <div>{ this.getFrenchDay(day) }</div>
                                                    <div className='checkbox switch m-0'>
                                                        <input type='checkbox' id={`working_${day}`} name={`working_${day}`} onChange={(event) => this.inputDayCheckHandling(event, day)} checked={this.getCheckedDay(day)} />
                                                        <label htmlFor={`working_${day}`} className='icon'></label>
                                                    </div>
                                                </th>
                                                <td className={`input-group-2${this.isWorkingDay(day)}`}>
                                                    <div className='row clearfix mb-2'>
                                                        <div className='col-sm-6'>
                                                            <select name={`${day}_morning_from_hours`} className='form-control' onChange=     {this.inputDayHandling} value={this.state.horaires[`${day}_morning_from_hours`]}>
                                                                { this.getSelectOptions(8, 14) }
                                                            </select>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <select name={`${day}_morning_from_minutes`} className='form-control' onChange={this.inputDayHandling} value={this.state.horaires[`${day}_morning_from_minutes`]}>
                                                                { this.getSelectOptions(0, 45, 15) }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-sm-6'>
                                                            <select name={`${day}_morning_to_hours`} className='form-control' onChange=     {this.inputDayHandling} value={this.state.horaires[`${day}_morning_to_hours`]}>
                                                                { this.getSelectOptions(8, 14) }
                                                            </select>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <select name={`${day}_morning_to_minutes`} className='form-control' onChange={this.inputDayHandling} value={this.state.horaires[`${day}_morning_to_minutes`]}>
                                                                { this.getSelectOptions(0, 45, 15) }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={`input-group-2${this.isWorkingDay(day)}`}>
                                                    <div className='row clearfix mb-2'>
                                                        <div className='col-sm-6'>
                                                            <select name={`${day}_evening_from_hours`} className='form-control' onChange=     {this.inputDayHandling} value={this.state.horaires[`${day}_evening_from_hours`]}>
                                                                { this.getSelectOptions(14, 24) }
                                                            </select>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <select name={`${day}_evening_from_minutes`} className='form-control' onChange={this.inputDayHandling} value={this.state.horaires[`${day}_evening_from_minutes`]}>
                                                                { this.getSelectOptions(0, 45, 15) }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='row clearfix mb-2'>
                                                        <div className='col-sm-6'>
                                                            <select name={`${day}_evening_to_hours`} className='form-control' onChange=     {this.inputDayHandling} value={this.state.horaires[`${day}_evening_to_hours`]}>
                                                                { this.getSelectOptions(14, 24) }
                                                            </select>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <select name={`${day}_evening_to_minutes`} className='form-control' onChange={this.inputDayHandling} value={this.state.horaires[`${day}_evening_to_minutes`]}>
                                                                { this.getSelectOptions(0, 45, 15) }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>
                    )}

                    { is_pro && (
                        <React.Fragment>
                            <div className='form-group'>
                                <label>Adresse</label>
                                <input type="text" name='address' className='form-control' defaultValue={user.address} onChange={this.inputHandling} />
                            </div>
                            <div className='form-group'>
                                <label>Catégorie <small className='red'>(*)</small></label>
                                <select className='form-control' name='user_category' onChange={this.inputHandling} required value={user.user_category}>
                                    <option value=''>-- Choisir la catégorie --</option>
                                    <option value='Restaurant'>Restaurant</option>
                                    <option value='Boucherie'>Boucherie</option>
                                </select>
                            </div>
                        </React.Fragment>
                    )}
                    
                    { this.state.is_pro && (
                        <div className='clearfix'>
                            <input type='checkbox' name='is_pro' className='d-hidden' checked={this.state.is_pro} onChange={this.isProHandling} />
                            <div className='form-group'>
                                <label>Raison sociale</label>
                                <input type="text" name='pro_name' className='form-control' defaultValue={pro.name} onChange={this.inputHandling} />
                            </div>
                            <div className='form-group'>
                                <label>E-mail pro</label>
                                <input type="text" name='pro_email' className='form-control' defaultValue={pro.email} onChange={this.inputHandling} />
                            </div>
                            <div className='form-group'>
                                <label>Téléphone pro</label>
                                <input type="text" name='pro_phone' className='form-control' defaultValue={pro.phone} onChange={this.inputHandling} />
                            </div>
                            <div className='form-group'>
                                <label>Adresse pro</label>
                                <input type="text" name='pro_address' className='form-control' defaultValue={pro.address} onChange={this.inputHandling} />
                            </div>
                        </div>
                    )}
                    
                    { this.state.error ? (<div className='alert alert-danger'>{ this.state.error }</div>) : '' }
                    { this.state.success ? (<div className='alert alert-success'>{ this.state.success }</div>) : '' }

                    <div className='mt-4'>
                        <button type='submit' className='btn w-100'>Enregistrer</button>
                    </div>
                </form>
            </div>
        );
    }

    getCheckedDay = (day) => {
        return this.state.horaires[`working_${day}`];
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ success: null });
        var user = this.state.user;
        var inputs = this.state.inputs;
        inputs['user_type'] = user.user_type;
        inputs['vehicle_type'] = this.state.livreur_vehicle;
        inputs['user_status'] = this.state.user_status ? 1 : 0;
        inputs['days'] = this.state.days;
        inputs['horaires'] = this.state.horaires;
        axios.put(apiUrl(`users/update`), inputs).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ error: data.message, success: null });
            } else {
                this.setState({ 
                    error: null, 
                    success: data.message,
                    deliverer_days: (data.deliverer_days || [])
                });
                if ( data.user ) {
                    authUser().persist(data.user);
                    if (typeof this.props.authenticate === "function") {
                        this.props.authenticate();
                    }
                }
            }
        });
    }

    getSelectOptions = (count_from, count_to, step=1) => {
        var options = [];
        for (let i = count_from; i <= count_to; i+=step) {
            var nbr = i;
            if ( i === 24 ) nbr = 0;
            var val = ("0" + nbr).slice(-2);
            options.push(<option value={val} key={`${nbr+Math.random()}`}>{`${val}`}</option>)
            //options.push(<option key={`${nbr+Math.random()}`}>{`${ ("0" + nbr).slice(-2) }:00`}</option>)
        }
        return options;
    }

    inputDayCheckHandling = (event) => {
        var horaires = this.state.horaires;
        var checkbox = event.target;
        if ( typeof horaires[checkbox.name] === 'undefined' ) {
            var day = checkbox.name.replace('working_', '');
            horaires[day + '_morning_from_hours'] = '08';
            horaires[day + '_morning_from_minutes'] = '00';
            horaires[day + '_morning_to_hours'] = '14';
            horaires[day + '_morning_to_minutes'] = '00';

            horaires[day + '_evening_from_hours'] = '14';
            horaires[day + '_evening_from_minutes'] = '00';
            horaires[day + '_evening_to_hours'] = '00';
            horaires[day + '_evening_to_minutes'] = '00';
        }
        horaires[checkbox.name] = checkbox.checked;
        this.setState({ horaires: horaires });
    }

    isWorkingDay = (day) => {
        var horaires = this.state.horaires;
        if ( horaires['working_' + day] ) return '';
        return ' disabled';
    }

    getFrenchDay = (day) => {
        const days = {};
        days['monday'] = 'Lundi';
        days['tuesday'] = 'Mardi';
        days['wednesday'] = 'Mercredi';
        days['thursday'] = 'Jeudi';
        days['friday'] = 'Vendredi';
        days['saturday'] = 'Samedi';
        days['sunday'] = 'Dimanche';
        return days[day];
    }

    changeLivreurVehicle = (v) => {
        this.setState({ livreur_vehicle: v });
    }

    userStatusHandling = (event) => {
        var checkbox = event.target;
        this.setState({ user_status: checkbox.checked });
    }

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

    inputDayHandling = (event) => {
        var horaires = this.state.horaires;
        var target = event.target;
        horaires[target.name] = target.value;
        this.setState({ horaires: horaires });
    }

    inputFileHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        if (target.files && target.files[0]) {
            var file = target.files[0];
            var extension = file.name.split('.').pop();
            window.loadImage.parseMetaData(file, data => {
                var orientation = 0;
                if (data.exif) {
                    orientation = data.exif.get('Orientation');
                }
                window.loadImage(file, (canvas) => {
                    var base64data = canvas.toDataURL('image/jpeg');
                    inputs[target.name] = base64data;
                    inputs[target.name + '_extension'] = extension;
                    this.setState({ inputs: inputs });
                }, { canvas: true, orientation: orientation});
            });
        }
    }
    
}

export default withRouter(Profil);
