import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import axios from 'axios';
import { apiUrl } from './../helpers/Global';

class SupportCreate extends Component 
{

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            auth_user: props.auth_user,
            inputs: {}
        };
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ success: ''});
        var inputs = this.state.inputs;
        const form = event.target;
        axios.post(apiUrl(`support/create`), inputs).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ error: data.message, success: '' });
            } else {
                this.setState({ success: data.message, error: '' }, function(){
                    form.reset();
                });
            }
        });
    }

    removeLevel() {
        window.ReactNativeWebView.postMessage('remove-level');
    }

    render() {
		return (
			<div id='support-new'>
				<div className='page-title'><h2>Nouveau message</h2></div>
				<div className='form-group text-center' style={{ margin: '15px auto' }}>
					<Link to='/support' replace className='btn w-100' onClick={this.removeLevel}>Liste des messages</Link>
				</div>
				<form className='form' method='post' onSubmit={this.handleSubmit}>
					<div className='form-group'>
						<label>Sujet <small className='red'>(*)</small></label>
						<input type='text' name='subject' className='form-control' onChange={this.inputHandling} required />
					</div>
					<div className='form-group'>
						<label>Message <small className='red'>(*)</small></label>
						<textarea name='message' className='form-control' rows='5' onChange={this.inputHandling} required></textarea>
					</div>
					{ this.state.error ? (<div className='alert alert-danger'>{ this.state.error }</div>) : '' }
					{ this.state.success ? (<div className='alert alert-success'>{ this.state.success }</div>) : '' }

					<button type='submit' className='btn w-100'>Envoyer</button>
				</form>
			</div>
		);
    }

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

}

export default withRouter(SupportCreate);
